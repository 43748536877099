<template>
    <div class="google-map" :id="mapName"></div>
</template>

<script>
    /* global google */

    export default {
        name: 'tui-google-map',

        props: {
            name: String,
            polygons: Array,
            center: {
                type: Object,
                default() {
                    return { lat: -34.6036844, lng: -58.3815591 };
                }
            },
            zoom: {
                type: Number,
                default: 14
            },
            editable: Boolean
        },

        data: function() {
            return {
                markers: [],
                mapName: this.name + '-map',
                polygonInstances: []
            };
        },

        mounted() {
            // Initialize the maps api
            const element = document.getElementById(this.mapName);
            this.map = new google.maps.Map(element, {
                zoom: this.zoom,
                center: new google.maps.LatLng(this.center.lat, this.center.lng),
                disableDefaultUI: true,
                zoomControl: true
            });

            // Initialize geocoder/places api
            this.geocoder = new google.maps.Geocoder();
            this.places = new google.maps.places.PlacesService(this.map);

            // Now initialize the polygons
            this.createPolygons();
        },

        watch: {
            polygons(value) {
                this.createPolygons();
            },

            editable() {
                if(this.editable) {
                    this.enableEditableMode();
                }
                else if(this.drawingManager) {
                    this.disableEditableMode();
                }
            }
        },

        methods: {
            enableEditableMode() {
                this.drawingManager = new google.maps.drawing.DrawingManager({
                    map: this.map,
                    drawingControl: true,
                    drawingControlOptions: {
                        position: google.maps.ControlPosition.TOP_CENTER,
                        drawingModes: [ 'polygon' ]
                    },
                    polygonOptions: {
                        strokeWeight: 2,
                        strokeColor: '#FF0000',
                        strokeOpacity: 0.8,
                        fillColor: '#FF0000',
                        fillOpacity: 0.45,
                        editable: true,
                        draggable: false
                    }
                });

                for(let polygon of this.polygonInstances) {
                    polygon.setOptions({
                        editable: true
                    });

                    // Add right-click events to the polygon
                    google.maps.event.addListener(polygon, 'rightclick', (event) => {
                        if(event.vertex === undefined) {
                            return;
                        }

                        this.onPolygonVertexRightClick(polygon, event);
                    });
                }

                google.maps.event.addListener(this.drawingManager, 'polygoncomplete', this.onPolygonCreated);
            },

            disableEditableMode() {
                this.drawingManager.setMap(null);
                this.drawingManager = null;

                for(let polygon of this.polygonInstances) {
                    polygon.setOptions({
                        editable: false
                    });

                    google.maps.event.clearInstanceListeners(polygon);
                }

                this.$emit('input', this.polygonInstances);
            },

            setCenter(loc) {
                this.map.setCenter(loc);
                this.map.setZoom(14);
            },

            setZoom(lvl) {
                this.map.setZoom(lvl);
            },

            setMarker(loc) {
                let marker = new google.maps.Marker({
                    map: this.map,
                    position: loc
                });
                this.markers.push(marker);
            },

            clearMarkers() {
                for(let m of this.markers) {
                    m.setMap(null);
                }

                this.markers = [];
            },

            createPolygons() {
                // Clear any existing polygons
                this.clearPolygons();

                // Construct the polygons. Make them editable if we are in edit mode
                if(this.polygons) {
                    for(let poly of this.polygons) {
                        let polygon = new google.maps.Polygon({
                            paths: poly,
                            strokeColor: '#FF0000',
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            fillColor: '#FF0000',
                            fillOpacity: 0.35
                        });

                        this.addPolygon(polygon);
                    }

                    this.$emit('input', this.polygonInstances);
                }
            },

            addPolygon(polygon) {
                polygon.setMap(this.map);

                this.polygonInstances.push(polygon);
            },

            removePolygon(polygon) {
                polygon.setMap(null);

                this.polygonInstances.splice(this.polygonInstances.indexOf(polygon), 1);
            },

            clearPolygons() {
                for(let p of this.polygonInstances) {
                    p.setMap(null);
                }

                this.polygonInstances = [];
            },

            getBounds(polygon) {
                let bounds = new google.maps.LatLngBounds();
                let paths = polygon.getPaths();
                let path;
                for(let i = 0; i < paths.getLength(); i++) {
                    path = paths.getAt(i);
                    for(let ii = 0; ii < path.getLength(); ii++) {
                        bounds.extend(path.getAt(ii));
                    }
                }
                return bounds;
            },

            getAddressInfo(params) {
                return new Promise((resolve, reject) => {
                    this.geocoder.geocode(params, (results, status) => {
                        if(status !== 'OK') {
                            return reject(new Error());
                        }

                        return resolve(results[0]);
                    });
                });
            },

            getPlaceDetails(id) {
                return new Promise((resolve, reject) => {
                    this.places.getDetails({
                        placeId: id,
                        fields: [ 'name', 'geometry', 'type' ]
                    }, (place, status) => {
                        if(status === 'OK') {
                            resolve(place);
                        }
                        else {
                            reject(new Error());
                        }
                    });
                });
            },

            onPolygonCreated(polygon) {
                // Go back to non-drawing mode
                this.drawingManager.setDrawingMode(null);

                this.addPolygon(polygon);

                // Add right-click events to the polygon
                google.maps.event.addListener(polygon, 'rightclick', (event) => {
                    if(event.vertex === undefined) {
                        return;
                    }

                    this.onPolygonVertexRightClick(polygon, event);
                });
            },

            onPolygonVertexRightClick(polygon, evt) {
                let path = polygon.getPath();
                if(path.getLength() <= 3) {
                    this.removePolygon(polygon);
                }
                else {
                    path.removeAt(evt.vertex);
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .google-map {
        margin: 0 auto;
        background: gray;
    }
</style>
