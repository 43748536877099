<template>
    <v-navigation-drawer
        v-model="drawerActive"
        right
        temporary
        fixed
        width="600"
    >
        <v-container class="pa-10">
            <v-row>
                <v-col>
                    <v-row class="align-center pb-6">
                        <v-btn icon class="black--text" @click="onCancelSelected">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <span class="headline" style="white-space: nowrap">Agregar datos de facturación</span>
                    </v-row>

                    <validation-observer ref="form" v-slot="{ valid }">
                        <v-form>
                            <validation-provider name="ivaCondition" v-slot="{ errors }" rules="required">
                                <v-select
                                    v-model="data.ivaCondition"
                                    :label="$t('fields.ivaCondition')"
                                    :items="optionsIvaCondition"
                                    item-text="name"
                                    item-value="value"
                                    :error-messages="errors"
                                />
                            </validation-provider>

                            <validation-provider name="cuit" v-slot="{ errors }" rules="required" v-if="data.ivaCondition === 3">
                                <v-text-field
                                    v-model="data.cuit"
                                    v-mask="maskDni"
                                    :label="$t('fields.customerDNI')"
                                    :error-messages="errors"
                                />
                            </validation-provider>

                            <validation-provider name="cuit" v-slot="{ errors }" rules="required" v-if="data.ivaCondition === 1">
                                <v-text-field
                                    v-model="data.cuit"
                                    v-mask="maskCuit"
                                    :label="$t('fields.customerCUIT')"
                                    :error-messages="errors"
                                />
                            </validation-provider>

                            <validation-provider name="businessName" v-slot="{ errors }" rules="required">
                                <v-text-field
                                    v-model="data.name"
                                    :label="$t('fields.businessName')"
                                    :error-messages="errors"
                                />
                            </validation-provider>

                            <validation-provider name="fiscalAddress" v-slot="{ errors }" rules="required">
                                <v-text-field
                                    v-model="data.address"
                                    :label="$t('fields.fiscalAddress')"
                                    :error-messages="errors"
                                />
                            </validation-provider>
                        </v-form>
                    </validation-observer>
                </v-col>
            </v-row>
        </v-container>

        <template v-slot:append>
            <v-row class="ma-4">
                <v-spacer></v-spacer>

                <v-btn color="primary" block dark @click="onConfirmSelected" :loading="isSaving">Guardar</v-btn>
            </v-row>
        </template>
    </v-navigation-drawer>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate';

    export default {
        components: {
            ValidationProvider,
            ValidationObserver
        },

        props: {
            customer: {
                type: Object,
                default: null
            },

            active: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                isSaving: false,
                data: {
                    name: '',
                    address: '',
                    ivaCondition: null,
                    cuit: ''
                },

                optionsIvaCondition: [
                    { name: 'Consumidor Final', value: 3 },
                    { name: 'Responsable Inscripto', value: 1 }
                ],

                maskDni: '########',
                maskCuit: '##-########-#'
            };
        },

        computed: {
            drawerActive: {
                get() {
                    return this.active;
                },

                set(val) {
                    this.$emit('update:active', val);
                }
            }
        },

        mounted() {
            this.data.ivaCondition = this.optionsIvaCondition[0].value;
        },

        methods: {
            async onConfirmSelected() {
                this.isSaving = true;

                let payload = {
                    customer: this.customer,
                    address: this.data
                };
                payload.address.cuit = payload.address.cuit.replace(/-/g, '');

                try {
                    await this.$store.dispatch('customer/addBillingAddress', payload);
                    this.$emit('update:active', false);
                }
                catch (err) {
                    if(err.code === 'error.request.invalid') {
                        let formErrors = {};

                        for(let errField in err.errors) {
                            formErrors[errField] = this.$t(err.errors[errField]);
                        }

                        this.$refs.form.setErrors(formErrors);
                    }
                }
                finally {
                    this.isSaving = false;
                }
            },

            onCancelSelected(value) {
                this.$emit('update:active', false);
            }
        }
    };
</script>
