var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{attrs:{"right":"","temporary":"","fixed":"","width":"600"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-row',{staticClass:"ma-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","block":"","dark":"","loading":_vm.isSaving},on:{"click":_vm.onConfirmSelected}},[_vm._v("Guardar")])],1)]},proxy:true}]),model:{value:(_vm.drawerActive),callback:function ($$v) {_vm.drawerActive=$$v},expression:"drawerActive"}},[_c('v-container',{staticClass:"pa-10"},[_c('v-row',[_c('v-col',[_c('v-row',{staticClass:"align-center pb-6"},[_c('v-btn',{staticClass:"black--text",attrs:{"icon":""},on:{"click":_vm.onCancelSelected}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('span',{staticClass:"headline",staticStyle:{"white-space":"nowrap"}},[_vm._v("Agregar datos de facturación")])],1),_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('v-form',[_c('validation-provider',{attrs:{"name":"ivaCondition","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":_vm.$t('fields.ivaCondition'),"items":_vm.optionsIvaCondition,"item-text":"name","item-value":"value","error-messages":errors},model:{value:(_vm.data.ivaCondition),callback:function ($$v) {_vm.$set(_vm.data, "ivaCondition", $$v)},expression:"data.ivaCondition"}})]}}],null,true)}),(_vm.data.ivaCondition === 3)?_c('validation-provider',{attrs:{"name":"cuit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.maskDni),expression:"maskDni"}],attrs:{"label":_vm.$t('fields.customerDNI'),"error-messages":errors},model:{value:(_vm.data.cuit),callback:function ($$v) {_vm.$set(_vm.data, "cuit", $$v)},expression:"data.cuit"}})]}}],null,true)}):_vm._e(),(_vm.data.ivaCondition === 1)?_c('validation-provider',{attrs:{"name":"cuit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.maskCuit),expression:"maskCuit"}],attrs:{"label":_vm.$t('fields.customerCUIT'),"error-messages":errors},model:{value:(_vm.data.cuit),callback:function ($$v) {_vm.$set(_vm.data, "cuit", $$v)},expression:"data.cuit"}})]}}],null,true)}):_vm._e(),_c('validation-provider',{attrs:{"name":"businessName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('fields.businessName'),"error-messages":errors},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"fiscalAddress","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('fields.fiscalAddress'),"error-messages":errors},model:{value:(_vm.data.address),callback:function ($$v) {_vm.$set(_vm.data, "address", $$v)},expression:"data.address"}})]}}],null,true)})],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }