<template>
    <v-navigation-drawer
        v-model="drawerActive"
        right
        temporary
        fixed
        width="600"
    >
        <v-container class="pa-10">
            <v-row>
                <v-col>
                    <v-row class="align-center pb-6">
                        <v-btn icon class="black--text" @click="onCancelSelected">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <span class="headline" style="white-space: nowrap">Agregar dirección de entrega</span>
                    </v-row>

                    <v-form>
                        <v-text-field
                            v-model="search"
                            label="Buscar dirección"
                        >
                            <template v-slot:append-outer>
                                <v-btn color="primary" @click="onSearchSelected">
                                    <v-icon>mdi-magnify</v-icon>
                                </v-btn>
                            </template>
                        </v-text-field>

                        <tui-google-map
                            name="map_2"
                            ref="map"
                            class="mb-8"
                            style="height: 200px;"
                        ></tui-google-map>

                        <span class="title">Información</span>

                        <v-text-field
                            label="Dirección"
                            v-model="shortAddress"
                            readonly
                            persistent-hint
                            :hint="shortAddressHint"
                        />

                        <v-text-field
                            label="Unidad"
                            v-model="data.unit"
                            persistent-hint
                            hint="Piso, depto y/o unidad"
                        />

                        <v-text-field
                            label="Comentario"
                            v-model="data.remarks"
                        />

                        <v-row class="py-0">
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field
                                    label="Teléfono"
                                    v-model="data.phone"
                                />
                            </v-col>

                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field
                                    label="Celular"
                                    v-model="data.cellphone"
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-col>
            </v-row>
        </v-container>

        <template v-slot:append>
            <v-row class="mr-4 mb-4">
                <v-spacer></v-spacer>

                <v-btn color="primary" dark @click="onConfirmSelected" :loading="isSaving">Guardar</v-btn>
            </v-row>
        </template>
    </v-navigation-drawer>
</template>

<script>
    /* global google */

    import TuiGoogleMap from '@/ui/TUIGoogleMap';

    export default {
        components: {
            TuiGoogleMap
        },

        props: {
            customer: {
                type: Object,
                default: null
            },

            active: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                isSaving: false,
                search: '',
                hasValidAddress: false,
                data: {
                    address: '',
                    location: null,
                    area: '',
                    place: '',
                    city: '',
                    postalCode: ''
                }
            };
        },

        computed: {
            shortAddress() {
                if(!this.data.address) {
                    return '';
                }

                return this.data.address.split(',')[0];
            },

            shortAddressHint() {
                return [
                    this.data.place,
                    this.data.postalCode,
                    this.data.area,
                    this.data.city
                ].filter(v => v).join(', ');
            },

            drawerActive: {
                get() {
                    return this.active;
                },

                set(val) {
                    this.$emit('update:active', val);
                }
            }
        },

        methods: {
            onSearchSelected() {
                let bounds = new google.maps.LatLngBounds(
                    new google.maps.LatLng(-35.068078, -59.219226),
                    new google.maps.LatLng(-34.260481, -57.673578)
                );

                let address = {};

                this.$refs.map.getAddressInfo({
                    address: this.search,
                    bounds: bounds,
                    componentRestrictions: { country: 'ar' }
                }).then((result) => {
                    address = result;
                    return this.$refs.map.getPlaceDetails(result.place_id);
                }).then((place) => {
                    this.$refs.map.clearMarkers();
                    this.$refs.map.setCenter(address.geometry.location);
                    this.$refs.map.setMarker(address.geometry.location);
                    this.$refs.map.setZoom(16);

                    // Fill in the form data
                    this.data.address = address.formatted_address;
                    this.data.location = address.geometry.location;

                    // If the search yielded some kind of place or establishment, let's add that info too.
                    this.data.place = null;
                    if(place.types) {
                        if(['point_of_interest', 'establishment', 'neighborhood'].some(r => place.types.includes(r))) {
                            this.data.place = place.name;
                        }
                    }

                    // additional address information
                    let components = {};
                    for(let i = 0; i < address.address_components.length; ++i) {
                        components[address.address_components[i].types[0]] = address.address_components[i].short_name;
                    }

                    this.data.area = components.political;
                    this.data.postalCode = components.postal_code;
                    this.data.city = components.administrative_area_level_1;

                    this.hasValidAddress = true;
                }).catch((err) => {
                    if(err) {
                        this.hasValidAddress = false;
                    }
                });
            },

            async onConfirmSelected() {
                this.isSaving = true;

                await this.$store.dispatch('customer/addShippingAddress', {
                    customer: this.customer,
                    address: this.data
                });

                this.isSaving = false;
                this.$emit('update:active', false);
            },

            onCancelSelected(value) {
                this.$emit('update:active', false);
            }
        }
    };
</script>
